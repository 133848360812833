import Homepage from './homepage';

function App() {
  return (
    <>
     <Homepage/> 
    </>
  );
}

export default App;
