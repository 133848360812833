import React, { useState } from "react";
import {
    MessageCircle,
    Brain,
    Globe2,
    Sparkles,
    Book,
    Languages,
    Clock,
    ChevronRight,
    Mail,
    Send,
    CheckCircle2,
    AlertCircle,
    Loader2
} from "lucide-react";

const Homepage = () => {
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("idle"); // idle, loading, success, error

    const handleWaitlist = (e) => {
        e.preventDefault();
        setStatus("loading");
        setTimeout(() => {
            setStatus("success");
            setEmail("");
        }, 1500);
    };

    return (
        <div className="min-h-screen bg-white">
            <main className="container mx-auto px-4 py-8 max-w-6xl">
                {/* Hero Section */}
                <section className="mb-24 text-center">
                    <div className="flex items-center justify-center space-x-2 mb-6">
                        <Brain className="h-8 w-8 text-blue-600" />
                        <span className="text-2xl font-bold text-gray-900">HYPUCP</span>
                    </div>

                    <div className="max-w-3xl mx-auto">
                        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6 leading-tight">
                            Realiza mejor tu trabajo mediante respuestas inteligentes con IA
                        </h1>
                        <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                            Potencia tu aprendizaje y productividad con tecnología de inteligencia artificial
                            diseñada específicamente para la comunidad universitaria peruana
                        </p>

                        <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-12">
                            <a href="https://hypucp-mvp.vercel.app/auth/login" target="_self" className="bg-blue-600 cursor-pointer text-white px-8 py-4 rounded-lg flex items-center hover:bg-blue-700 transition-all transform hover:scale-105">
                                <Sparkles className="mr-2 h-5 w-5" />
                                Comienza Aquí
                            </a>
                            <a href="https://drive.google.com/file/d/12g7o8FEjvUDjIz4FEU4D11sR1jx4PtRO/view?usp=sharing" target="_blank" className="bg-gray-100 cursor-pointer text-gray-800 px-8 py-4 rounded-lg flex items-center hover:bg-gray-200 transition-all">
                                <Globe2 className="mr-2 h-5 w-5" />
                                Ver Demo
                            </a>
                        </div>
                    </div>

                    <div className="relative rounded-xl bg-gradient-to-r from-blue-50 to-indigo-50 p-8 mt-12">
                        <img
                            src="https://maresone.s3.us-east-1.amazonaws.com/woman-user.jpg"
                            alt="User using app"
                            className="rounded-lg shadow-xl mx-auto max-w-md"
                        />
                    </div>
                </section>

                {/* Features Section */}
                <section className="mb-24">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold mb-4">
                            ¿Cómo te ayuda HYPUCP?
                        </h2>
                        <p className="text-gray-600 max-w-2xl mx-auto">
                            Nuestra plataforma combina lo mejor de la inteligencia artificial
                            con las necesidades específicas de los estudiantes
                        </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-12">
                        <div className="space-y-8">
                            <div className="flex items-start p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                                    <Book className="h-6 w-6 text-blue-600" />
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold mb-2">
                                        Búsqueda Inteligente
                                    </h3>
                                    <p className="text-gray-600">
                                        Encuentra la información de tus cursos de manera rápida e inteligente
                                        utilizando procesamiento de lenguaje natural avanzado.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                                <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                                    <Languages className="h-6 w-6 text-green-600" />
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold mb-2">
                                        Traducción Multicultural
                                    </h3>
                                    <p className="text-gray-600">
                                        Traduce tus contenidos a lenguas originarias andino-amazónicas del Perú,
                                        preservando el conocimiento ancestral.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                                <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                                    <Clock className="h-6 w-6 text-purple-600" />
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold mb-2">
                                        Respuestas en Tiempo Real
                                    </h3>
                                    <p className="text-gray-600">
                                        Obtén respuestas instantáneas a tus preguntas académicas,
                                        con fuentes verificadas y contenido actualizado.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative">
                            <img
                                src="https://maresone.s3.us-east-1.amazonaws.com/male-user.jpg"
                                alt="Person using phone"
                                className="w-full rounded-xl shadow-2xl"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-xl" />
                        </div>
                    </div>
                </section>

                {/* Waitlist Section */}
                <section className="mb-24 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-8 md:p-12">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-bold mb-4">
                            Únete a la lista de espera
                        </h2>
                        <p className="text-gray-600 mb-8">
                            Sé uno de los primeros en experimentar el futuro del aprendizaje
                            universitario potenciado por IA
                        </p>

                        <form onSubmit={handleWaitlist} className="flex flex-col md:flex-row gap-4">
                            <div className="flex-1">
                                <input
                                    type="email"
                                    placeholder="tu@email.com"
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={status === "loading" || status === "success"}
                                className="bg-blue-600 text-white px-6 py-3 rounded-lg flex items-center justify-center hover:bg-blue-700 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {status === "idle" && (
                                    <>
                                        <Send className="mr-2 h-5 w-5" />
                                        Unirme ahora
                                    </>
                                )}
                                {status === "loading" && (
                                    <>
                                        <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                                        Procesando...
                                    </>
                                )}
                                {status === "success" && (
                                    <>
                                        <CheckCircle2 className="mr-2 h-5 w-5" />
                                        ¡Registrado!
                                    </>
                                )}
                                {status === "error" && (
                                    <>
                                        <AlertCircle className="mr-2 h-5 w-5" />
                                        Inténtalo de nuevo
                                    </>
                                )}
                            </button>
                        </form>
                    </div>
                </section>

                {/* Demo Section */}
                <section className="text-center mb-24">
                    <h2 className="text-3xl font-bold mb-6">
                        Agenda una demostración virtual GRATIS
                    </h2>
                    <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                        Descubre cómo HYPUCP puede transformar tu experiencia universitaria
                        con una demostración personalizada
                    </p>

                    <button className="bg-green-600 text-white px-8 py-4 rounded-lg flex items-center justify-center mx-auto hover:bg-green-700 transition-all transform hover:scale-105">
                        <MessageCircle className="mr-2" />
                        Envianos un mensaje
                    </button>
                </section>

                {/* Social Links */}
                <section className="text-center mb-16">
                    <h3 className="text-xl font-semibold mb-6">Conéctate con nosotros</h3>
                    <div className="flex justify-center space-x-6">
                        <a href="#" className="text-blue-600 hover:opacity-80 transition-opacity">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                            </svg>
                        </a>
                        <a href="#" className="text-pink-600 hover:opacity-80 transition-opacity">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                            </svg>
                        </a>
                        <a href="#" className="text-gray-800 hover:opacity-80 transition-opacity">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                            </svg>
                        </a>
                    </div>
                </section>

                {/* Footer */}
                <footer className="text-sm text-gray-600 text-center border-t pt-8">
                    <p>Copyright © 2024 HYPUCP - Todos los derechos reservados.</p>
                </footer>
            </main>
        </div>
    );
};

export default Homepage;